
.action-window {
    height: 200px;
    width: 427px;
    position: absolute;
    right: 50px;
    top: 50px;
    display: flex;
    flex-direction: row;
    z-index: 1;
}

.action-window-left {
    border-right: none !important;
    border-top-right-radius: 0 !important;;
    border-bottom-right-radius: 0 !important;;
    width: 300px;
    height: 200px;
}

.action-window-left:after {
    right: 0 !important;;
    border-top-right-radius: 0 !important;;
    border-bottom-right-radius: 0 !important;;
}

.action-window-right {
    border-left: none !important;;
    border-radius: 0 50% 50% 0 !important;;
    width: 200px !important;;
    height: 200px !important;;
}

.action-window-right:after {
    border-radius: 0 50% 50% 0 !important;;
}

.small-action-window {
    width: 265px;
}

.small-action-window-left {
    border-right: none !important;
    border-top-right-radius: 0 !important;;
    border-bottom-right-radius: 0 !important;;
    width: 160px;
    height: 200px;
}

.small-action-window-right {
    border-left: none !important;;
    border-radius: 0 50% 50% 0 !important;;
    width: 300px !important;;
    height: 200px !important;;
}

.action-window-inner {
    position: absolute;
    margin: 15px;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    display: flex;
}

.action-button {
    flex: 1;
}

.action-button-holder {
    align-self: normal;
    flex-direction: row;
    display: flex;
    padding:5px;
}

.action-list-box {
    height: 100%;
    width: 170px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.action-health-bar-outer {
    height: 100%;
    width: 15px;
    border-radius: 15px;
    border: 2px solid transparent;
    overflow: hidden;
    background: linear-gradient(#535353, #2B2B2B);
    z-index: 3;
    margin-left: 20px;
}

.action-health-bar-inner {
    width: 15px;
    bottom: 0;
    z-index: 3;
    position: absolute;
    box-shadow: inset 0 0 23px 1px rgba(0, 0, 0, 0.47);
}

.radar-outer {
    border-radius: 50%;
    width: 165px;
    position: absolute;
    right: 5px;
    height: 165px;
}

.radar {
    border-radius: 50%;
    width: 165px;
    position: absolute;
    right: 5px;
    height: 165px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 165px 165px;
    background-repeat: no-repeat;
}

.radar-icon {
    position: absolute;
    width: 100px;
    height: 100px;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

