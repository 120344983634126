
.main-window {
    height: 400px;
    width: 500px;
    position: absolute;
    left: 50px;
    top: 50px;
    display: flex;
    flex-direction: row;
    z-index: 1;
}

.main-window-border {
    height: 400px;
    width: 500px;
    padding: 0 !important;
    overflow: scroll
}

.main-window-title-holder {
    position: relative;
    width: 100%;
    height: 37px;
    border-top-left-radius: 6px;
    overflow:hidden;
}

.main-window-title {
    position: absolute;
    height: 35px;
    width: 70%;
    background-color: #CCC;
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
    background-size: 10px 10px;
    padding: 5px 5px 5px 22px;
    font-size: 21px;
    font-weight: 600;
    clip-path: polygon(0% 0%, 0% 100%, 85% 100%, 100% 0%, 0% 0%);
}

.main-window-title-border {
    position: absolute;
    left: 0;
    top: 0;
    height: 37px;
    width: calc(70% + 4px);
    background-color: black;
    background-size: 10px 10px;
    padding: 5px 5px 5px 22px;
    font-size: 21px;
    font-weight: 600;
    clip-path: polygon(0% 0%, 0% 100%, 85% 100%, 100% 0%, 0% 0%);
}

.main-window-inner {
    padding: 10px;
}
